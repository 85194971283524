import { Injectable } from '@angular/core';

export interface Selection {
  value: string;
  viewValue: string;
}

@Injectable()
export class PropertyOptions {

  typeOptions: Selection [] = [
    {value: 'CASA', viewValue: 'Casa'},
    {value: 'APARTAMENTO', viewValue: 'Apartamento'},
    {value: 'FINCA', viewValue: 'Finca'},
    {value: 'COMERCIAL', viewValue: 'Comercial'},
    {value: 'LOTE', viewValue: 'Lote'}
  ];

  subtypeOptions: {} = {
    'CASA': [
      {value: 'RURAL', viewValue: 'Rural'},
      {value: 'URBANA', viewValue: 'Urbana'}
    ],
    'APARTAMENTO': [
      {value: 'CONJUNTO_CERRADO', viewValue: 'Conjunto cerrado'},
      {value: 'CONJUNTO_ABIERTO', viewValue: 'Conjunto abierto'}
    ],
    'FINCA': [
      {value: 'RECREATIVA', viewValue: 'Recreativa'},
      {value: 'AGRICOLA', viewValue: 'Agrícola'},
      {value: 'GANADERA', viewValue: 'Ganadera'}
    ],
    'COMERCIAL': [
      {value: 'BODEGA', viewValue: 'Bodega'},
      {value: 'CONSULTORIO', viewValue: 'Consultorio'},
      {value: 'OFICINA', viewValue: 'Oficina'}

    ],
    'LOTE': [
      {value: 'URBANO_COMERCIAL', viewValue: 'Urbano comercial'},
      {value: 'URBANO_RESIDENCIAL', viewValue: 'Urbano residencial'},
      {value: 'RURAL_COMERCIAL', viewValue: 'Rural comercial'},
      {value: 'RURAL_RESIDENCIAL', viewValue: 'Rural residencial'}
    ],
  };

  antiquenessOptions: Selection [] = [
    {value: 'NUEVA', viewValue: 'Nueva'},
    {value: 'UNO_A_CUATRO_ANIOS', viewValue: '1-4 años'},
    {value: 'CUATRO_A_DIEZ_ANIOS', viewValue: '4-10 años'},
    {value: 'DIEZ_A_VEINTE_ANIOS', viewValue: '10-20 años'},
    {value: 'VEINTE_MAS_ANIOS', viewValue: '20+'}
  ];

  apartmentTypeOptions: Selection [] = [
    {value: 'PENTHOUSE', viewValue: 'Penthouse'},
    {value: 'DUPLEX', viewValue: 'Duplex'},
    {value: 'UN_NIVEL', viewValue: 'Un nivel'}
  ];

  kitchenTypeOptions: Selection [] = [
    {value: 'TRADICIONAL_MAMPOSTERIA', viewValue: 'Tradicional mampostería'},
    {value: 'SEMI_INTEGRAL_MESON_ACERO', viewValue: 'Semi-integral mesón acero'},
    {value: 'INTEGRAL_MESON_ACERO_MARMOL', viewValue: 'Integral mesón acero marmol'}
  ];

  waterHeaterOptions: Selection [] = [
    {value: 'NO_DISPONIBLE', viewValue: 'No disponible'},
    {value: 'GAS', viewValue: 'Gas'},
    {value: 'ELECTRICO', viewValue: 'Eléctrico'},
    {value: 'OTRO', viewValue: 'Otro'}
  ];

  parkingSpaceTypeOptions: Selection [] = [
    {value: 'CUBIERTO', viewValue: 'Cubierto'},
    {value: 'DESCUBIERTO', viewValue: 'Descubierto'},
    {value: 'SUBTERRANEO', viewValue: 'Subterráneo'}
  ];

  securityOptions: Selection [] = [
    {value: 'NO_DISPONIBLE', viewValue: 'No disponible'},
    {value: 'VIGILANTE', viewValue: 'Vigilante'},
    {value: 'TARJETA', viewValue: 'Tarjeta'},
    {value: 'LLAVE', viewValue: 'Llave'},
    {value: 'VIDEO_PORTERO', viewValue: 'Video portero'}
  ];

  wetAreasOptions: Selection [] = [
    {value: 'PISCINA_NINOS', viewValue: 'Piscina niños'},
    {value: 'PISCINA_ADULTOS', viewValue: 'Piscina adultos'},
    {value: 'JACUZZI', viewValue: 'Jacuzzi'},
    {value: 'TURCO', viewValue: 'Turco'},
    {value: 'SAUNA', viewValue: 'Sauna'},
    {value: 'SPA', viewValue: 'SPA'}
  ];

  powerPlantOptions: Selection [] = [
    {value: 'NO_DISPONIBLE', viewValue: 'No disponible'},
    {value: 'PARCIAL', viewValue: 'Parcial'},
    {value: 'TOTAL', viewValue: 'Total'}
  ];
  altitudeOptions: Selection [] = [
    {value: 'CALIDO', viewValue: 'Calido - 0 y 1000 metros'},
    {value: 'TEMPLADO', viewValue: 'Templado - 1000 y 2000 metros'},
    {value: 'FRIO', viewValue: 'Frio - 2000 y 3000 metros'},
    {value: 'PARAMO', viewValue: 'Páramo - 3000 y 4000 metros'},
    {value: 'GLACIAL', viewValue: 'Glacial - 4000 y el everest'}
  ];

  topographyOptions: Selection [] = [
    {value: 'TERRENO', viewValue: 'Terreno'},
    {value: 'PLANO', viewValue: 'Plano'},
    {value: 'QUEBRADO', viewValue: 'Quebrado'}
  ];

  sportFieldsOptions: Selection [] = [
    {value: 'SQUASH', viewValue: 'Squash'},
    {value: 'FUTBOL', viewValue: 'Futbol'},
    {value: 'BALONCESTO', viewValue: 'Baloncesto'},
    {value: 'VOLEIBOL', viewValue: 'Voleibol'},
    {value: 'MICRO', viewValue: 'Micro'},
    {value: 'TENNIS', viewValue: 'Tennis'}
  ];

  commercialOperationOptions: Selection [] = [
    {value: 'ARRIENDO', viewValue: 'Arriendo'},
    {value: 'LEASING', viewValue: 'Leasing'},
    {value: 'PERMUTACION', viewValue: 'Permutación'},
    {value: 'VENTA', viewValue: 'Venta'},
    {value: 'ARRIENDO_CORTO_PLAZO', viewValue: 'Arriendo a corto plazo'}
  ];

  contactTypeOptions: Selection [] = [
    {value: 'seller', viewValue: 'Vendedor'},
    {value: 'security', viewValue: 'Vigilancia'},
    {value: 'keys', viewValue: 'Llaves'},
    {value: 'tenant', viewValue: 'Inquilino'},
    {value: 'projectContact', viewValue: 'Responsable proyecto'}
  ];

  OrderFilterOptions: Selection [] = [
    {value: '-created_at', viewValue: 'Más reciente'},
    {value: 'created_at', viewValue: 'Más antigüo'},
    {value: '-updated_at', viewValue: 'Actualizado recientemente'},
    {value: 'commercial__price', viewValue: 'Precio (ascendente)'},
    {value: '-commercial__price', viewValue: 'Precio (descendente)'},
    {value: 'area__total_area', viewValue: 'Área (ascendente)'},
    {value: '-area__total_area', viewValue: 'Área (descendente)'},
    {value: 'interior__rooms', viewValue: 'Habitaciones (ascendente)'},
    {value: '-interior__rooms', viewValue: 'Habitaciones (descendente)'}
  ];
}
