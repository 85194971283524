import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';


import {NgRedux} from '@angular-redux/store';
import {IAppState} from '../store';

@Injectable()
export class AuthorizeResolve implements Resolve<any> {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private store: NgRedux<IAppState>
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authenticationService.authorization().then(() => {
      this.store.getState();
    }, () => {
      // remove user from local storage to log user out
      localStorage.removeItem('token');
      this.router.navigate(['inicio-sesion'], { queryParams: { returnUrl: state.url }});
    });
  }
}
