import { Injectable } from '@angular/core';
import {Restangular} from "ngx-restangular";


import {NgRedux} from "@angular-redux/store";
import {IAppState} from "../store";
import {CounterActions} from "../store/actions";
import {Router} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {WebsocketService} from "./websocket.service";
import {Subject, Subscription} from "rxjs";
import {AgentsService} from "./agents.service";

@Injectable()
export class AuthenticationService {
  connected: Subscription;
  isConnected = false;
  messages: Subject<any>;

  constructor(
    private restangular: Restangular,
    private store: NgRedux<IAppState>,
    private actions: CounterActions,
    private router: Router,
    private httpClient: HttpClient,
    private _websocket: WebsocketService,
    private agentsService: AgentsService
  ) { }

  /**
   *  Makes the login request
   * @param userData
   * @returns {any}
   */
  signin(userData) {
    const authenticationURL = this.restangular.one('auth/login');
    Object.assign(authenticationURL, userData);
    return authenticationURL.post();
  }

  /**
   * Creates new users.. this request makes with conventional http client due to failed with restangular
   * @param userData
   * @returns {Observable<Object>}
   */
  signup(userData: FormData) {
    const  httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json'
      })
    };
    return this.httpClient.post(environment.url_authentication + 'public/users', userData, httpOptions);
  }

  /**
   * Remove user from local storage to log user out and redirects to inicio-sesion
   */
  signOut() {
    localStorage.clear();
    this.router.navigate(['inicio-sesion']).then(() => {
      this.actions.deleteAuthorization();
    });
  }

  /**
   * Get users data using a promise before access to the platform
   * @returns {Promise<any>}
   */
  authorization() {
    return new Promise((resolve, reject) => {
      this.restangular.one('auth/user').get().subscribe(authorization => {
        // this.connectWebsocket();
        this.agentsService.getAllUsers();
        resolve(this.store.dispatch(this.actions.saveAuthorization(authorization.plain())));
      }, () => {
        reject();
      });
    });
  }

  updateUser(userData:FormData) {
    const  httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        'Accept': 'application/json'
      })
    };
    return this.httpClient.put(environment.url_authentication + 'auth/user', userData, httpOptions);
  }

  verifyUser(token) {
    this.restangular.one('public/users/verify/' + token).get();
  }

  connectWebsocket() {
    const auth = localStorage.getItem('token');
    const address: any = 'ws://localhost:8080/user/' + auth; // change to correct url
    this.connected = this._websocket.connected().subscribe(status => {
      this.isConnected = status;
      // this._changeDetectorRef.detectChanges()
    });
    this.messages = <Subject<any>>this._websocket.connect(address)
      .map((response: MessageEvent): any => {
        return response.data;
      });
    const messageLog = this.messages.scan((current, change) => {
      return [...current, JSON.parse(change)]
    }, []);
    messageLog.subscribe((event) =>{
      console.log(event);
    });
  }

}
