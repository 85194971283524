import {Injectable} from "@angular/core";
import {MatSnackBar} from "@angular/material";
import {Router} from "@angular/router";
import {CounterActions} from "../store/actions";


@Injectable()
export class NotificationService {

  constructor(
    private actions: CounterActions,
    private _snackBar: MatSnackBar,
    private router : Router
  ){}

  errorMessage(error) {
    console.log(error);
     const message = 'Lo sentimos ocurrió un error';
     const action = 'cerrar';
      this._snackBar.open(message, action, {
        duration: 5000,
        verticalPosition: "bottom",
        horizontalPosition: 'right'
      });

    if(error.status == 401 || error.status_code == 401) {
      localStorage.clear();
      this.router.navigate(['inicio-sesion']).then(() => {
        this.actions.deleteAuthorization();
      });
    }
  }
}
