import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
// Routing
import {AuthRouting} from "./shared/auth-routing.guard";
// Resolve
import {AuthorizeResolve} from "./shared/resolves/authorize.resolve";
// Components
export const routes: Routes = [
  { path: '', redirectTo: 'inicio-sesion', pathMatch: 'full' },
  { path: '', loadChildren: './authentication/authentication.module#AuthenticationModule' },
  {path: 'plataforma', loadChildren: './platform/platform.module#PlatformModule',
    canActivateChild: [AuthRouting],
    resolve: {
      authorize: AuthorizeResolve
    },
  },
  { path: '**', redirectTo: 'inicio-sesion' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, onSameUrlNavigation: 'reload', scrollPositionRestoration: 'top',})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
