import { Injectable } from '@angular/core';
import { Action } from 'redux';

@Injectable()
export class CounterActions {

  static SAVE_AUTHORIZATION = 'SAVE_AUTHORIZATION';
  static DELETE_AUTHORIZATION = 'DELETE_AUTHORIZATION';

  saveAuthorization(authorization): Action {
    const action = { type: CounterActions.SAVE_AUTHORIZATION, authorization: authorization };
    return action;
  }

  deleteAuthorization(): Action {
    return { type: CounterActions.DELETE_AUTHORIZATION };
  }

}
