import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule, HttpHeaders} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {CurrencyPipe, registerLocaleData} from '@angular/common';
// import locales
import COP from '@angular/common/locales/es-CO';
// Third part libraries
// Models
import {RestangularModule} from 'ngx-restangular';
import {NgProgress, NgProgressModule} from '@ngx-progressbar/core';
// @angular-redux/store
import {NgRedux, NgReduxModule} from '@angular-redux/store';
import {CounterActions} from './shared/store/actions';
import {IAppState, INITIAL_STATE, rootReducer} from './shared/store';

import {environment} from '../environments/environment';
// Routing
import {AppRoutingModule} from './app.routing';
import {AuthRouting} from './shared/auth-routing.guard';
// Services
import {AuthenticationService} from './shared/services/authentication.service';
// Resolves
import {AuthorizeResolve} from './shared/resolves/authorize.resolve';
// Global entity
import {PropertyOptions} from './shared/models/property-options';
// Components
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NotificationService} from "./shared/services/notification.service";
import {MatSnackBarModule} from "@angular/material";
import {WebsocketService} from "./shared/services/websocket.service";
import {AgentsService} from "./shared/services/agents.service";
import {Apollo, ApolloModule} from "apollo-angular";
import {HttpLink, HttpLinkModule} from "apollo-angular-link-http";
import {ApolloLink} from "apollo-link";
import {InMemoryCache} from "apollo-cache-inmemory";

registerLocaleData(COP, 'es');


/// Restangular configuration
export function RestangularConfigFactory(RestangularProvider, ngProgress, notificationService) {

  RestangularProvider.setBaseUrl(environment.url_authentication);

  RestangularProvider.addFullRequestInterceptor((element, operation, path, url, headers, params) => {
    ngProgress.ref('root').start();
    // Check token's existence (if exist add to headers)
    return {
      params: Object.assign({}, params), // Default query string params .assign({}, params, { sort:"name" })
      headers: Object.assign({}, headers, {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json'
      }),
      element: element
    };
  });

  RestangularProvider.addResponseInterceptor((data, operation, what, url, response) => {
    ngProgress.ref('root').complete();
    return data;
  });

  RestangularProvider.addErrorInterceptor((response, subject, responseHandler) => {
    ngProgress.ref('root').complete();
    notificationService.errorMessage(response);
    return response;
  });

}


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    RestangularModule.forRoot([NgProgress, NotificationService], RestangularConfigFactory),
    NgProgressModule,
    NgReduxModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MatSnackBarModule,
    // Graphql and apollo client
    ApolloModule,
    HttpLinkModule,
  ],
  providers: [
    AuthRouting,
    CounterActions,
    CurrencyPipe,

    // Services
    AuthenticationService,
    AgentsService,
    NotificationService,
    WebsocketService,
    // Resolves
    AuthorizeResolve,
    // Global entity
    PropertyOptions
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // ng-redux configuration
  constructor(
    ngRedux: NgRedux<IAppState>,
    // Graphql and apollo client
    private apollo: Apollo,
    private httpLink: HttpLink
  ) {
    ngRedux.configureStore(
      rootReducer,
      INITIAL_STATE
    );
    // Graphql and apollo client
    const http = httpLink.create({uri: environment.url_estate + 'gql'});
    const middleware = new ApolloLink((operation, forward) => {
      const token = localStorage.getItem('token');
      if (token) {
        operation.setContext({
          headers: new HttpHeaders().set('Authorization', `Bearer ${token}`)
        });
      }
      return forward(operation);
    });
    apollo.create({
      link: middleware.concat(http),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          errorPolicy: 'all'
        }
      }
    });

  }
}
