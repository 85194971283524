import { Action } from 'redux';
import { CounterActions } from './actions';

// States
export interface IAppState {
  authorization?: object; // ? = Set optional property
}

export const INITIAL_STATE: IAppState = {
  authorization: null
};


export function rootReducer(lastState: IAppState, action: Action): IAppState {

  const newState = {
    authorization: lastState.authorization
  };

  switch (action.type) {
    case CounterActions.SAVE_AUTHORIZATION:
      newState.authorization = action['authorization'];
      return newState;
    case CounterActions.DELETE_AUTHORIZATION:
      newState.authorization = null;
      return newState;
    default:
      return newState;
  }

}
