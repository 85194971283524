import {Injectable} from "@angular/core";
import gql from "graphql-tag";
import {environment} from "../../../environments/environment";
import {Apollo} from "apollo-angular";


@Injectable()

export class AgentsService {

  constructor(
    private apollo: Apollo,
  ) { }

  userListQuery = gql `
    query user {
      users {
        edges {
          node {
            id
            firstName
            lastName
            email
          }
        }
      }
    }`;



  getAllUsers() {
    this.apollo.query<any>({
      query: this.userListQuery,
      context: {
        uri: environment.url_authentication + 'users/gql'
      }
    }).subscribe((agents) => {
      if (!agents.loading) {
        const agentsList = [];
        agents.data.users.edges.forEach((agent) => {
          agentsList.push(agent.node);
        });
        localStorage.setItem('agents', JSON.stringify(agentsList));
      }
    });
  }


}
