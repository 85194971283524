import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from "@angular/forms";
import {Meta} from "@angular/platform-browser";
import {environment} from "../environments/environment";

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  public innerWidth: 'isMobile' | 'isLargeMobile' | 'isTablet' | 'isLaptop' | 'isDesktop';
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.selectWindowSize();
  }



  constructor(
    private meta: Meta
  ) {}

  /**
   *  Creates a Meta tag for google tag manager, depending if it's running on the dev or production mode
   */
  ngOnInit() {
    if (environment.production) {
      this.meta.addTag({ name: 'googlebot-news', content: 'all' });

    } else {
      this.meta.addTag({ name: 'googlebot-news', content: 'noindex' });
    }

    this.selectWindowSize();

  }

  ngAfterViewInit(): void { }


  selectWindowSize() {
    this.innerWidth = window.innerWidth < 560 ? 'isMobile': window.innerWidth < 768 ? 'isLargeMobile' : window.innerWidth < 992 ? 'isTablet' : window.innerWidth < 1200 ? 'isLaptop': 'isDesktop';
  }

}
